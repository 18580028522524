import React, {Fragment} from 'react';
import Helmet from 'react-helmet';
import {Col, Grid} from 'jsxstyle';
import {graphql} from 'gatsby';

import Layout from '@layouts';
import Section from '@components/layout/Section';
import Hero from '@components/Hero';
import Image from '@components/Image';
import PreFooter from '@components/PreFooter';
import SectionTitle from '@components/SectionTitle';
import {LIGHT_GREY, ORANGE} from '@styles/colors';
import {stripTagsFromWagtail} from '@util';

import {SCHEMA_MAIN} from '@util/googleSchema';

type Props = {
  imageSrc: string,
  name: string,
};

const PartnershipLogo = ({imageSrc, name}: Props) => (
  <Col
    width="250px"
    height="200px"
    background={LIGHT_GREY}
    justifyContent="center"
    alignItems="center"
  >
    <Image
      imageSrc={imageSrc}
      title={name}
      alt={name}
      imgStyle={{position: 'relative'}}
    />
  </Col>
);

export default ({data}: Object) => {
  const {page} = data.wagtail;
  const {
    seoTitle,
    searchDescription,
    heroHeadline,
    heroCopy,
    heroImage,
    partnershipsHeadline,
    partnershipsCopy,
    partnerships,
  } = page;
  const sectionRef = React.createRef();
  return (
    <Fragment>
      <Helmet>
        <html lang="en" />
        <title>{seoTitle}</title>
        <meta name="description" content={searchDescription} />
        <script type="application/ld+json">
          {JSON.stringify(SCHEMA_MAIN)}
        </script>
      </Helmet>
      <Layout>
        <Hero
          title={heroHeadline}
          background={`linear-gradient(rgba(20, 20, 20, 0.3), rgba(20, 20, 20, 0.3)), url(${
            heroImage.url
          })`}
          // body={stripTagsFromWagtail(heroCopy)}
          scrollToLink
          copy={'Learn More'}
          sectionRef={sectionRef}
          noOffer
        />
        <Section sectionRef={sectionRef}>
          <SectionTitle
            dividerColor={ORANGE}
            width="55%"
            headline={partnershipsHeadline}
            copy={stripTagsFromWagtail(partnershipsCopy)}
            alignItems="center"
            textAlign="center"
          />
          <Grid
            alignSelf="center"
            gridTemplateColumns={'repeat(auto-fit, minmax(250px, 1fr))'}
            maxWidth="1360px"
            justifyItems={'center'}
            gridGap="30px"
            margin="0 auto"
          >
            {partnerships &&
              partnerships.map(({image, headline}) => (
                <PartnershipLogo
                  key={headline}
                  name={headline}
                  imageSrc={image && image.url}
                />
              ))}
          </Grid>
        </Section>
        <PreFooter
          headline="Interested in becoming a resident?"
          copy="Get in touch with us to find your perfect Fetner apartment."
        />
      </Layout>
    </Fragment>
  );
};

export const query = graphql`
  fragment PartnershipsFragment on Wagtail_PartnershipsPage {
    seoTitle
    searchDescription
    heroHeadline
    heroCopy
    heroImage {
      url
    }
    heroCtaText
    heroCtaLink
    heroOfferHeadline
    heroOfferCopy
    partnershipsHeadline
    partnershipsCopy
    partnerships {
      image {
        url
      }
      headline
    }
  }
  query {
    wagtail {
      page(slug: "partnerships") {
        ...PartnershipsFragment
      }
    }
  }
`;
