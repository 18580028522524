// @flow

import React from 'react';
import {Block} from 'jsxstyle';

import Section from '@components/layout/Section';
import SectionTitle from '@components/SectionTitle';
import Link from '@components/Link';
import Button from '@components/Button';
import {BLUE, WHITE, ORANGE, TRANSPARENT} from '@styles/colors';
import FetnerLogo from '@components/icons/FetnerLogo';
import FetnerEsrtLogo from '@components/icons/FetnerEsrtLogo';

import type {Element} from 'react';

type Props = {
  headline: string,
  copy: string,
  logo: boolean,
  queryParams?: string,
};

const mediaQueries = {
  sm: 'screen and (max-width: 768px)',
  lg: 'screen and (min-width: 1280px)',
};

export default ({headline, copy, logo, queryParams}: Props): Element<*> => {
  const isTheEpic =
    headline
      ?.split('at')[1]
      ?.trim()
      .replace(/\?/g, '') === 'The Epic';
  const isProperties = logo;

  return (
    <Section background={ORANGE} padding="80px 0">
      <SectionTitle
        headline={headline ? headline : 'Interested in becoming a resident?'}
        copy={
          copy
            ? copy
            : 'Get in touch with us to find your perfect Fetner apartment.'
        }
        dividerColor={BLUE}
        copyColor={WHITE}
        button={
          <div>
            <Button
              buttonLink={queryParams ? "/contact/" + queryParams : "/contact/"}
              buttonText="contact us"
              buttonColor={TRANSPARENT}
              buttonBorder={`1px solid ${WHITE}`}
            />
          </div>
        }
      >
        <Block
          component="span"
          color={WHITE}
          margin="-20px 0 40px 0"
          textAlign="center"
          mediaQueries={mediaQueries}
          smMargin="0 0 20px 0"
        >
          Call us at
          <Link
            href="tel: 212.427.9700"
            style={{
              margin: '0 0 40px 10px',
              textDecoration: 'underline',
              color: WHITE,
              fontWeight: 'bold',
            }}
          >
            212.427.9700
          </Link>
        </Block>
      </SectionTitle>
      {isTheEpic ? (
        <Block
          mediaQueries={mediaQueries}
          smJustifyContent="center"
          justifyContent="right"
          display="flex"
          alignItems="center"
          margin="75px 60px -50px"
        >
          <FetnerLogo />
        </Block>
      ) : isProperties && !isTheEpic ? (
        <Block
          mediaQueries={mediaQueries}
          smJustifyContent="center"
          justifyContent="right"
          display="flex"
          alignItems="center"
          gap="24px"
          margin="75px 60px -50px"
        >
          <FetnerEsrtLogo />
          <Block width="1px" height="30px" backgroundColor={WHITE} />
          <FetnerLogo />
        </Block>
      ) : (
        ''
      )}
    </Section>
  );
};
