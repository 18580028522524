export const SCHEMA_MAIN = {
  '@context': 'https://schema.org',
  '@type': 'LocalBusiness',
  name: 'Fetner Properties LLC',
  image:
    'https://www.fetner.com/icons-3496f2024bc59c5c934c6cd320d17a9c/favicon.ico',
  '@id': '',
  url: 'https://www.fetner.com/',
  telephone: '+1 (212) 427-9700',
  address: {
    '@type': 'PostalAddress',
    streetAddress: '780 Third Avenue',
    addressLocality: 'New York',
    addressRegion: 'NY',
    postalCode: '10017',
    addressCountry: 'US',
  },
  geo: {
    '@type': 'GeoCoordinates',
    latitude: 40.751077,
    longitude: -73.97380220000002,
  },
  openingHours: ['Mo-Su 09:00-17:00'],
};

export const SCHEMA_298_MULBERRY = {
  '@context': 'https://schema.org/',
  '@type': ['ApartmentComplex', 'Product'],
  name: '298 Mulberry',
  brand: 'Fetner',
  address: {
    '@type': 'PostalAddress',
    streetAddress: '298 Mulberry St',
    addressLocality: 'New York',
    addressRegion: 'NY',
    postalCode: '10012',
    addressCountry: 'US',
    telephone: '+1 (212) 360-1783',
    mainEntityOfPage: 'https://www.fetner.com/properties/298-mulberry/',
    name: '298 Mulberry',
  },
  logo: {
    '@type': 'ImageObject',
    contentUrl: 'http://www.fetner.com/media/property/cover/the-victory_1.jpg',
  },
  map:
    'https://www.google.com/maps/place/298+Mulberry+St,+New+York,+NY+10012,+USA',
  mainEntityOfPage: 'https://www.fetner.com/properties/298-mulberry/',
  geo: {
    '@type': 'GeoCoordinates',
    latitude: 40.7250884,
    longitude: -73.9966011,
  },
  image:
    'https://www-fetner-com-production.s3.amazonaws.com/media/images/Hero_The_Victory.original.jpg',
  description:
    '298 Mulberry Street is a full-service luxury rental property located in NoHo, New York City. Living at 298 Mulberry Street means surrounding yourself with New York City’s most celebrated restaurants, shops, and cultural destinations.',
};

export const SCHEMA_VICTORY = {
  '@context': 'https://schema.org/',
  '@type': ['ApartmentComplex', 'Product'],
  name: 'The Victory',
  brand: 'Fetner',
  address: {
    '@type': 'PostalAddress',
    streetAddress: '561 Tenth Ave',
    addressLocality: 'New York',
    addressRegion: 'NY',
    postalCode: '10036',
    addressCountry: 'US',
    telephone: '+1 (212) 695-5505',
    mainEntityOfPage: 'https://www.fetner.com/properties/the-victory/',
    name: 'The Victory',
  },
  logo: {
    '@type': 'ImageObject',
    contentUrl: 'http://www.fetner.com/media/property/cover/the-victory_1.jpg',
  },
  map:
    'https://maps.google.com/maps?q=561%20Tenth%20Ave,%20New%20York,%20NY%2010036',
  mainEntityOfPage: 'https://www.fetner.com/properties/the-victory/',
  geo: {
    '@type': 'GeoCoordinates',
    latitude: 40.7592799,
    longitude: -74.00067,
  },
  image:
    'https://www-fetner-com-production.s3.amazonaws.com/media/images/Hero_The_Victory.original.jpg',
  description:
    "Looking for a luxury New York City apartment that gives you more? On the vibrant Westside of Midtown Manhattan, The Victory features handpicked finishes and Equip by Fetner amenities specially designed to suit your lifestyle. Unwind in the resident lounge or tone up in the cross-training room. Enjoy the convenience of life near several subway lines, world-class dining and a happening nightlife. These are some of life's little victories. Live triumphantly.",
};

export const SCHEMA_EPIC = {
  '@context': 'https://schema.org/',
  '@type': ['ApartmentComplex', 'Product'],
  name: 'The Epic',
  brand: 'Fetner',
  address: {
    '@type': 'PostalAddress',
    streetAddress: '125 West 31st St',
    addressLocality: 'New York',
    addressRegion: 'NY',
    postalCode: '10001',
    addressCountry: 'US',
    telephone: '+1 (212) 554-2700',
    mainEntityOfPage: 'https://www.fetner.com/properties/the-epic/',
    name: 'The Epic',
  },
  logo: {
    '@type': 'ImageObject',
    contentUrl: 'http://www.fetner.com/media/property/cover/the-epic_1.jpg',
  },
  map:
    'https://maps.google.com/maps?q=125%20West%2031st%20St,%20New%20York,%20NY%2010001',
  mainEntityOfPage: 'https://www.fetner.com/properties/the-epic/',
  geo: {
    '@type': 'GeoCoordinates',
    latitude: 40.74863,
    longitude: -73.9946564,
  },
  image:
    'https://www-fetner-com-production.s3.amazonaws.com/media/images/Hero_The_Epic.original.jpg',
  description:
    'The Epic is the next generation of green NYC luxury rental apartments. The Epic is a Gold LEED-certified apartment building located in iconic Midtown NYC . The top of the building houses 400 luxury rental units with unparalleled views, while the bottom third of the building is shared by the American Cancer Society and the Friary for the Franciscans of the Holy Name. Ready to Live Greater? Live The Epic.',
};

export const SCHEMA_345 = {
  '@context': 'https://schema.org/',
  '@type': ['ApartmentComplex', 'Product'],
  name: '345 E 94',
  address: {
    '@type': 'PostalAddress',
    streetAddress: '345 East 94th St',
    addressLocality: 'New York',
    addressRegion: 'NY',
    postalCode: '10128',
    addressCountry: 'US',
    telephone: '+1 (212) 360-1783',
    mainEntityOfPage: 'https://www.fetner.com/properties/345-e-94/',
    name: '345 E 94',
  },
  logo: {
    '@type': 'ImageObject',
    contentUrl:
      'http://www.fetner.com/media/property/cover/345-e-94_vG62P7t.jpg',
  },
  map:
    'https://maps.google.com/maps?q=345%20East%2094th%20St,%20New%20York,%20NY%2010128',
  mainEntityOfPage: 'https://www.fetner.com/properties/345-e-94/',
  geo: {
    '@type': 'GeoCoordinates',
    latitude: 40.7824832,
    longitude: 73.9501412,
  },
  image:
    'https://www-fetner-com-production.s3.amazonaws.com/media/images/Hero_345E94.original.jpg',
  description:
    'Welcome to 345 East 94 in Yorkville, the Upper East Side neighborhood with a small-town feel. Just feet away from the 2nd Avenue subway, 345 East 94 is close to a variety of shopping and dining options, with the esplanade providing for some “me time” whenever you need it. Start your day with a morning run along the waterfront, or take your four-legged friend to Carl Schurz Park. Our full-service, pet-friendly property features an expansive terrace with BBQ, a state-of-the-art exercise studio, and sweeping views of the East River and beyond.',
  brand: 'Fetner',
};


export const SCHEMA_MULBERRY = {
  '@context': 'https://schema.org/',
  '@type': ['ApartmentComplex', 'Product'],
  name: '298 mulberry',
  address: {
    '@type': 'PostalAddress',
    streetAddress: '345 East 94th St',
    addressLocality: 'New York',
    addressRegion: 'NY',
    postalCode: '10128',
    addressCountry: 'US',
    telephone: '+1 (212) 360-1783',
    mainEntityOfPage: 'https://www.fetner.com/properties/298-mulberry/',
    name: '298 mulberry',
  },
  logo: {
    '@type': 'ImageObject',
    contentUrl:
      'http://www.fetner.com/media/property/cover/345-e-94_vG62P7t.jpg',
  },
  map:
    'https://maps.google.com/maps?q=345%20East%2094th%20St,%20New%20York,%20NY%2010128',
  mainEntityOfPage: 'https://www.fetner.com/properties/298-mulberry/',
  geo: {
    '@type': 'GeoCoordinates',
    latitude: 40.7824832,
    longitude: 73.9501412,
  },
  image:
    'https://www-fetner-com-production.s3.amazonaws.com/media/images/Hero_345E94.original.jpg',
  description:
    'Welcome to 345 East 94 in Yorkville, the Upper East Side neighborhood with a small-town feel. Just feet away from the 2nd Avenue subway, 345 East 94 is close to a variety of shopping and dining options, with the esplanade providing for some “me time” whenever you need it. Start your day with a morning run along the waterfront, or take your four-legged friend to Carl Schurz Park. Our full-service, pet-friendly property features an expansive terrace with BBQ, a state-of-the-art exercise studio, and sweeping views of the East River and beyond.',
  brand: 'Fetner',
};
