import React from 'react';

export default () => (
  <svg
    width="101"
    height="28"
    viewBox="0 0 101 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M100.322 0V27.0471H0.939941V0H100.322ZM26.8048 17.2471H33.0276V18.9683H24.8979V8.07919H32.9504V9.81442H26.8048V12.6245H32.2515V14.3316H26.8048V17.2471ZM53.4303 11.2405L59.4213 18.9683H61.0192V8.07919H59.1404V15.6032L53.3214 8.07919H51.5656V18.9683H53.4408L53.4303 11.2405ZM75.0371 18.9683H66.9144V8.07919H74.9669V9.80037H68.8248V12.6105H74.268V14.3316H68.8248V17.2471H75.0371V18.9683ZM82.3141 18.9683H80.4038V8.07919H85.257C86.6266 8.07919 87.6801 8.49368 88.3824 9.17864C88.9826 9.80859 89.3074 10.6516 89.285 11.5215V11.5532C89.285 13.3376 88.2139 14.4089 86.7073 14.841L89.6467 18.9683H87.3816L84.7127 15.1817H82.3247L82.3141 18.9683ZM85.1055 13.4921H82.3137V9.84247H85.0915C86.5032 9.84247 87.339 10.4783 87.339 11.6444V11.6761C87.339 12.7896 86.4716 13.4921 85.1055 13.4921ZM2.73432 1.79504H98.5241V25.2522H2.73432V1.79504ZM41.0649 18.9683H42.9894V9.86008H46.4309V8.07919H37.6199V9.8355H41.0649V18.9683ZM19.0007 14.5319H13.5224L13.5294 18.9683H11.6225V8.07919H19.6996V9.8355H13.5224V12.7931H19.0007V14.5319Z"
      fill="white"
    />
  </svg>
);
